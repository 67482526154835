import { throttle } from 'lodash';

export function windowAction() {
  window.addEventListener('DOMContentLoaded', () => {
    accordionUserAgentToggle();
  });

  window.addEventListener(
    'resize',
    _.throttle(
      (e) => {
        accordionResizeToggle();
        return false;
      },
      100,
      { trailing: true, leading: true }
    )
  );
}

function accordionUserAgentToggle() {
  const details = document.querySelectorAll('.js-acDetails');

  if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
    details.forEach((target) => {
      target.removeAttribute('open');
    });
  } else {
    details.forEach((target) => {
      target.setAttribute('open', 'true');
    });
  }
}

function accordionResizeToggle() {
  const details = document.querySelectorAll('.js-acDetails');
  const scroll = window.scrollY;

  // スクロール量がkvの高さを超えたらページトップボタンを表示
  if (window.matchMedia('(max-width: 767px)').matches) {
    details.forEach((target) => {
      target.removeAttribute('open');
    });
  } else if (window.matchMedia('(min-width:768px)').matches) {
    details.forEach((target) => {
      target.setAttribute('open', 'true');
    });
  }
}
