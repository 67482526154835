export function processTabConfig() {
  const IS_ACTIVE = 'is-active';
  const IS_VIEW = 'is-view';
  const aboutCatItem = document.querySelectorAll('.js-aboutCatItem');
  const aboutCont = document.querySelectorAll('.js-aboutCont');
  const aboutTabA = document.querySelectorAll('.js-aboutTabA');
  const aboutProcessA = document.querySelectorAll('.js-aboutProcessA');
  const aboutDetailA = document.querySelectorAll('.js-aboutDetailA');
  const aboutTabB = document.querySelectorAll('.js-aboutTabB');
  const aboutProcessB = document.querySelectorAll('.js-aboutProcessB');
  const aboutDetailB = document.querySelectorAll('.js-aboutDetailB');

  /** 産婦人科と不妊治療施設のタブ切り替え */
  aboutCatItem.forEach((target) => {
    target.addEventListener('click', () => {
      const aboutCatData = target.dataset.aboutcat;

      // 産婦人科&不妊治療施設タブアクティブ用class全削除
      for (let i = 0; i < aboutCatItem.length; i++) {
        aboutCatItem[i].classList.remove(IS_ACTIVE);
      }

      // 産婦人科&不妊治療施設コンテンツ表示用class全削除
      for (let i = 0; i < aboutCont.length; i++) {
        aboutCont[i].classList.remove(IS_VIEW);
      }

      for (let i = 0; i < aboutCont.length; i++) {
        const aboutContData = aboutCont[i].dataset.aboutcont;

        if (aboutCatData == aboutContData) {
          aboutCont[i].classList.add(IS_VIEW);
        }
      }

      target.classList.add(IS_ACTIVE);
    });
  });

  /** 産婦人科PROCESS内ボタンでのタブ切り替え */
  aboutProcessA.forEach((target) => {
    target.addEventListener('click', () => {
      const aboutProcessData = target.dataset.aboutprocess;

      aboutTabChangeA(target, aboutProcessData, aboutProcessA, aboutTabA, aboutDetailA);

      for (let i = 0; i < aboutTabA.length; i++) {
        const aboutTabData = aboutTabA[i].dataset.abouttab;

        if (aboutProcessData == aboutTabData) {
          aboutTabA[i].classList.add(IS_ACTIVE);
        }
      }
    });
  });

  /** 産婦人科コンテンツ内のタブ切り替え */
  aboutTabA.forEach((target) => {
    target.addEventListener('click', () => {
      const aboutTabData = target.dataset.abouttab;

      aboutTabChangeA(target, aboutTabData, aboutTabA, aboutProcessA, aboutDetailA);

      for (let i = 0; i < aboutProcessA.length; i++) {
        const aboutProcessData = aboutProcessA[i].dataset.aboutprocess;

        if (aboutTabData == aboutProcessData) {
          aboutProcessA[i].classList.add(IS_ACTIVE);
        }
      }
    });
  });

  /** 産婦人科PROCESS内ボタンでのタブ切り替え */
  aboutProcessB.forEach((target) => {
    target.addEventListener('click', () => {
      const aboutProcessData = target.dataset.aboutprocess;

      aboutTabChangeA(target, aboutProcessData, aboutProcessB, aboutTabB, aboutDetailB);

      for (let i = 0; i < aboutTabB.length; i++) {
        const aboutTabData = aboutTabB[i].dataset.abouttab;

        if (aboutProcessData == aboutTabData) {
          aboutTabB[i].classList.add(IS_ACTIVE);
        }
      }
    });
  });

  /** 産婦人科コンテンツ内のタブ切り替え */
  aboutTabB.forEach((target) => {
    target.addEventListener('click', () => {
      const aboutTabData = target.dataset.abouttab;

      aboutTabChangeA(target, aboutTabData, aboutTabB, aboutProcessB, aboutDetailB);

      for (let i = 0; i < aboutProcessB.length; i++) {
        const aboutProcessData = aboutProcessB[i].dataset.aboutprocess;

        if (aboutTabData == aboutProcessData) {
          aboutProcessB[i].classList.add(IS_ACTIVE);
        }
      }
    });
  });

  function aboutTabChangeA(target, thisData, array01, array02, array03) {
    // 産婦人科コンテンツ内タブアクティブ用class全削除
    for (let i = 0; i < array01.length; i++) {
      array01[i].classList.remove(IS_ACTIVE);
    }

    for (let i = 0; i < array02.length; i++) {
      array02[i].classList.remove(IS_ACTIVE);
    }

    // 産婦人科コンテンツ内カテゴリ表示用class全削除
    for (let i = 0; i < array03.length; i++) {
      array03[i].classList.remove(IS_VIEW);
    }

    for (let i = 0; i < array03.length; i++) {
      const aboutDetailData = array03[i].dataset.aboutdetail;

      if (thisData == aboutDetailData) {
        array03[i].classList.add(IS_VIEW);
      }
    }

    target.classList.add(IS_ACTIVE);
  }

  function aboutTabChangeB(target, thisData, array01, array02, array03) {
    // 産婦人科コンテンツ内タブアクティブ用class全削除
    for (let i = 0; i < array01.length; i++) {
      array01[i].classList.remove(IS_ACTIVE);
    }

    for (let i = 0; i < array02.length; i++) {
      array02[i].classList.remove(IS_ACTIVE);
    }

    // 産婦人科コンテンツ内カテゴリ表示用class全削除
    for (let i = 0; i < array03.length; i++) {
      array03[i].classList.remove(IS_VIEW);
    }

    for (let i = 0; i < array03.length; i++) {
      const aboutDetailData = array03[i].dataset.aboutdetail;

      if (thisData == aboutDetailData) {
        array03[i].classList.add(IS_VIEW);
      }
    }

    target.classList.add(IS_ACTIVE);
  }
}
