import Lenis from '@studio-freight/lenis';

export function smoothScroll() {
  const hamburgerStatusEle = document.querySelector('.js-hamburgerStatus');
  const lenis = new Lenis();

  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);

  hamburgerStatusEle.addEventListener('click', () => {
    if (hamburgerStatusEle.classList.contains('is-active')) {
      lenis.stop();
    } else {
      lenis.start();
    }
  });
}
