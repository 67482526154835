import Aos from 'aos';

export function aosConfig() {
  let aosOffset;
  if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
    aosOffset = Number(160);
  } else {
    aosOffset = Number(250);
  }

  window.addEventListener('load', () => {
    Aos.init({
      offset: aosOffset,
      duration: 1000,
      once: true,
    });
  });
}
