import Swiper, { EffectFade, Autoplay } from 'swiper';

export function loadingAnim() {
  if (document.querySelector('.js-indexLoading')) {
    const headerLogoEle = document.querySelector('.js-headerLogo');
    const indexLoadingEle = document.querySelector('.js-indexLoading');
    const IS_ACTIVE = 'is-active';
    const IS_HOVER = 'is-hover';
    const IS_ANIMATED = 'is-animated';

    window.addEventListener('load', () => {
      setTimeout(() => {
        indexLoadingEle.classList.add(IS_ACTIVE);

        // 画面幅が変わった際に再度ロゴの位置調整transitionが発生するのを防ぐclassを付与
        setTimeout(() => {
          indexLoadingEle.classList.add(IS_ANIMATED);

          if (document.querySelector('.js-indexSlider')) {
            const slideIndexHero = new Swiper('.js-indexSlider', {
              modules: [EffectFade, Autoplay],
              effect: 'fade',
              loop: true,
              autoplay: {
                delay: 5000,
                disableOnInteraction: false,
              },
            });
          }
        }, 2000);
      }, 300);
    });

    headerLogoEle.addEventListener('mouseover', () => {
      if (indexLoadingEle.classList.contains(IS_ANIMATED)) {
        indexLoadingEle.classList.add(IS_HOVER);
      }
    });

    headerLogoEle.addEventListener('mouseleave', () => {
      indexLoadingEle.classList.remove(IS_HOVER);
    });
  }
}
