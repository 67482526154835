export function hamburgerToggle() {
  const bodyEle = document.querySelector('body');
  const headerEle = document.getElementById('header');
  const headerNavEle = document.querySelector('.js-headerNav');
  const hamburgerEle = document.querySelector('.js-hamburger');

  hamburgerEle.addEventListener('click', () => {
    headerNavEle.classList.toggle('is-view');
    hamburgerEle.classList.toggle('is-active');

    if (hamburgerEle.classList.contains('is-active')) {
      hamburgerEle.classList.remove('is-close');
      headerNavEle.classList.add('is-view');
      bodyEle.classList.add('is-fixed');
    } else {
      hamburgerEle.classList.add('is-close');
      headerNavEle.classList.remove('is-view');
      bodyEle.classList.remove('is-fixed');
    }
  });
}

export function megaMenu() {
  const headerMegaEle = document.querySelectorAll('.js-headerMega');
  const IS_VIEW = 'is-view';

  headerMegaEle.forEach((target) => {
    const targetMenu = target.querySelector('.js-headerMegaCont');

    target.addEventListener('mouseover', () => {
      targetMenu.classList.add(IS_VIEW);
    });

    target.addEventListener('mouseleave', () => {
      targetMenu.classList.remove(IS_VIEW);
    });
  });
}
