import ScrollHint from 'scroll-hint';

export function scrollHint() {
  window.addEventListener('DOMContentLoaded', () => {
    new ScrollHint('.js-scrollable', {
      i18n: {
        scrollable: 'スクロールできます',
      },
    });
  });
}
