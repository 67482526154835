export function toolTipView() {
  /** 予約・受付 */
  const toolReserve = ['予約管理機能', '受付管理機能', '患者管理機能', '診察カレンダー機能', 'インフォメーション機能']; // 予約・受付
  const toolReserveOnly = ['予約登録', '予約変更・キャンセル', '仮患者予約登録', '予約患者メール送信', '予約リマインダーメール送信', '予約管理', ' 診察時刻通知メール送信', '予約状況サマリー', '予約コメント登録', '新規患者アンケート', 'Web問診']; // 予約管理機能
  const toolReceptionOnly = ['直来患者受付', '外出・外出戻り受付', '保険証確認', '総合受付番号発行', '予約キャンセルメール', '診察一時中断表示', '待ち時間表示', '受付管理', '来院状況サマリー', '出産予定日・出産場所・一時金状況表示', '受付コメント登録', 'マルチパーパス']; // 受付管理機能
  const toolPatient = ['新規患者登録', '予約操作ロック', '患者管理', '個別メール送信', '紹介状作成(診療情報提供書)', '患者コメント登録', 'グループ属性管理']; // 患者管理機能
  const toolCalendar = ['診療予約カレンダー管理', 'カレンダーテンプレート', 'カレンダーコピー', '一括休診設定']; // 診察カレンダー機能
  const toolInfo = ['お知らせ掲載', '再来受付機お知らせ表示', '連絡掲示板', '待合モニターコンテンツ表示', '院内スケジュール管理']; // インフォメーション機能

  /** 業務支援 */
  const toolSupport = ['入院管理機能', '手術・処置管理機能', '出産管理機能', '出産・予後調査管理機能', '凍結管理機能']; // 業務支援
  const toolHospitalization = ['病室管理', '入院管理', '入院状況サマリー', 'クリティカルパス（＝クリニカルパス）']; // 入院管理機能
  const toolSurgery = ['手術予定管理', '処置予定管理', '手術・処置リマインダーメール送信']; // 手術・処置管理機能
  const toolChildbirth = ['出産予定管理', '出産実績管理', '当院出産予定管理', '出産状況サマリー', '分娩台帳作成', '出生届作成', '帝王切開履歴管理', 'マタニティカレンダー']; // 出産管理機能
  const toolChildbirthResearch = ['調査対象者登録', '出産調査結果確認', '出産予後調査結果確認', '調査結果ダウンロード']; // 出産・予後調査管理機能
  const toolFrozen = ['凍結検体登録', '凍結入金管理', '凍結継続管理', 'ペア患者管理']; // 凍結管理機能

  /** マーケティング */
  const toolMarkting = ['データ分析機能', 'メール配信機能', 'アンケート機能']; // マーケティング
  const toolData = ['患者データ分析', '待ち時間データ分析', '来院患者エリアデータ分析', '初診データ分析', '認知媒体クロスデータ分析', '患者データダウンロード', '来院データ分析', '出産方法別出産データ分析', '出産患者エリアデータ分析']; // データ分析機能
  const toolMailDelivery = ['メール原稿登録', '一斉メール配信', '定期メール配信', '配信予定メール確認', ' 配信結果確認']; // メール配信機能
  const toolEnquete = ['アンケート作成', 'アンケート回答データ分析']; // アンケート機能

  if (document.querySelector('.js-tooltip')) {
    const toolTipEle = document.querySelectorAll('.js-tooltip');

    toolTipEle.forEach((target) => {
      /**
       * ツールチップアイコンをマウスオーバー(PC)した時に要素生成
       */
      target.addEventListener('mouseover', () => {
        if (!target.querySelector('.c-introIco_tooltipFlow')) {
          const targetToolName = target.dataset.tool;
          tooltipSwitch(target, targetToolName);
        } else {
          const tooltipFlowEle = document.querySelector('.c-introIco_tooltipFlow');
          tooltipFlowEle.remove();

          const targetToolName = target.dataset.tool;
          tooltipSwitch(target, targetToolName);
        }
      });

      /**
       * ツールチップからマウスを話した時に生成した要素を削除
       */
      target.addEventListener('mouseleave', () => {
        const tooltipFlowEle = document.querySelector('.c-introIco_tooltipFlow');
        tooltipFlowEle.remove();
      });
    });
  }

  function tooltipSwitch(target, targetName) {
    switch (targetName) {
      /** 予約・受付 */
      case 'toolReserve':
        arrayCreate(target, toolReserve);
        break;

      case 'toolReserveOnly':
        arrayCreate(target, toolReserveOnly);
        break;

      case 'toolReceptionOnly':
        arrayCreate(target, toolReceptionOnly);
        break;

      case 'toolPatient':
        arrayCreate(target, toolPatient);
        break;

      case 'toolCalendar':
        arrayCreate(target, toolCalendar);
        break;

      case 'toolInfo':
        arrayCreate(target, toolInfo);
        break;

      /** 業務支援 */
      case 'toolSupport':
        arrayCreate(target, toolSupport);
        break;

      case 'toolHospitalization':
        arrayCreate(target, toolHospitalization);
        break;

      case 'toolSurgery':
        arrayCreate(target, toolSurgery);
        break;

      case 'toolChildbirth':
        arrayCreate(target, toolChildbirth);
        break;

      case 'toolChildbirthResearch':
        arrayCreate(target, toolChildbirthResearch);
        break;

      case 'toolFrozen':
        arrayCreate(target, toolFrozen);
        break;

      /** マーケティング */
      case 'toolMarkting':
        arrayCreate(target, toolMarkting);
        break;

      case 'toolData':
        arrayCreate(target, toolData);
        break;

      case 'toolMailDelivery':
        arrayCreate(target, toolMailDelivery);
        break;

      case 'toolEnquete':
        arrayCreate(target, toolEnquete);
        break;

      default:
        break;
    }
  }
}

function arrayCreate(element, array) {
  const tooltipCont = document.createElement('span');
  tooltipCont.className = 'c-introIco_tooltipFlow';

  for (let i = 0; i < array.length; i++) {
    tooltipCont.innerHTML += '<span class="c-introIco_tooltipFlowItem c-cardBox_tooltip">' + array[i] + '</span>';
  }

  element.appendChild(tooltipCont);
}
