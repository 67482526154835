const { accordion } = require('./modules/accordion');
const { anchorSmoothMove } = require('./modules/anchorSmoothLink');
const { aosConfig } = require('./modules/aosConfig');
const { contactConfig } = require('./modules/contactConfig');
const { hamburgerToggle, megaMenu } = require('./modules/headerUiAction');
const { loadingAnim } = require('./modules/loadingAnim');
const { pageTop } = require('./modules/pageTop');
const { processTabConfig } = require('./modules/processTabConfig');
const { scrollHint } = require('./modules/scrollHint');
// const { indexSlider } = require('./modules/sliderConfig');
const { smoothScroll } = require('./modules/smoothScroll');
const { toolTipView } = require('./modules/toolTipView');
const { mvAnimation } = require('./modules/topPageMvAnimation');
const { windowAction } = require('./modules/windowAction');

loadingAnim();
contactConfig();
hamburgerToggle();
anchorSmoothMove();
accordion();
windowAction();
smoothScroll();
aosConfig();

mvAnimation();

toolTipView();
processTabConfig();
scrollHint();
megaMenu();
// indexSlider();
pageTop();
