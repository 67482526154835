export function contactConfig() {
  if (document.querySelector('.js-formSelect')) {
    const formSelectEle = document.querySelector('.js-formSelect select');

    const blankOption = formSelectEle.querySelector('option[value=""]');
    if (blankOption) {
      blankOption.textContent = '選択してください'; // 任意のテキストに変更
    }
  }
}
